<template>
  <v-autocomplete
    @input="input"
    :hide-no-data="loading || !searchInput"
    :items="items"
    :label="label"
    :loading="loading"
    :no-data-text="$t('noData')"
    :search-input.sync="searchInput"
    hide-details
    item-text="name"
    item-value="key"
    return-object
    v-model="value"
  >
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <img :src="data.item.avatar.url" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle :class="getCssClass(data.item.status)">
            {{ $t("status." + data.item.status) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import _debounce from "lodash/debounce";
import api from "@/api";
import { AVAILABLE, AWAY, BUSY, IDLE } from "@/status";

export default {
  name: "GroupMemberInput",
  props: {
    existingMembers: Array,
    label: String,
  },
  data() {
    return {
      items: [],
      loading: false,
      searchInput: null,
      value: null,
    };
  },
  computed: {
    existingUserIds() {
      return this.existingMembers.map((u) => u.id);
    },
    myUserId() {
      return this.$store.state.user.id;
    },
  },
  created() {
    this.search = _debounce(this.search, 500);
  },
  watch: {
    searchInput() {
      this.loading = Boolean(this.searchInput);
      this.search();
    },
  },
  methods: {
    getCssClass(status) {
      if (status === AVAILABLE) return "success--text";
      if (status === BUSY) return "success--text";
      return "";
    },
    input(member) {
      if (member) {
        this.$emit("member-selected", member);
        this.$nextTick(() => {
          this.searchInput = "";
          this.value = "";
        });
      }
    },
    search() {
      if (this.searchInput) {
        api
          .listUsers(this.searchInput)
          .then((response) => {
            this.items = response.data.filter(
              (user) =>
                !this.existingUserIds.includes(user.id) &&
                user.id !== this.myUserId
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.items = [];
      }
    },
  },
  i18n: {
    messages: {
      de: {
        noData: "Keine Treffer",
        status: {
          [AVAILABLE]: "verfügbar",
          [AWAY]: "abwesend",
          [BUSY]: "beschäftigt",
          [IDLE]: "offline",
        },
      },
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
.v-input >>> .v-select__selections {
  min-height: 32px;
}
</style>